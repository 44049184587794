var odc = {
		    /**
		     * 최초 실행 시 필요한 변수 정의 및 이벤트 함수 실행
		     */
		    init: function () {
		        var self = this;

		        self.odcContent = $('.odc-content');
		        self.superCategoryId = "CT50020000"
		        //self.superCategoryId = self.odcContent.data('superCategoryId');

		        //self.twoDepthCategoryRequestData();
		        //self.heroBannerCarousel();
		        self.showRoomType1Carousel();
		        self.showRoomType2Carousel();
		        //self.recommendedTab();
		        //self.recommendedListRequestData();
		        //self.popularProductRequestData();
		        //self.bestReviewRequestData();
		        //self.exhibitionPromotionRequestData();
		        //self.eventRequestData();
		        //self.myProductRequestData();
		    },

		    /**
		     * 공통 : 전체보기
		     */
		    allView: function (target) {
		        var $target = $(target);
		        var $allViewButton = $target.find('.odc-all-view');
		        var $viewTarget =  $target.find($allViewButton.data('allViewTarget'));
		        var item = $(target).find($target.data('allViewItem'));
		        var limitMobile = $target.data('allViewLimitMobile');
		        var limitPc = $target.data('allViewLimitPc');

		        function allViewSetting() {
		            if (window.innerWidth > 767) {
		                if (item.length > limitPc) {
		                    $allViewButton.show();
		                } else {
		                    $allViewButton.hide();
		                    $viewTarget.removeClass('is-active');
		                }
		            } else {
		                if (item.length > limitMobile) {
		                    $allViewButton.show();
		                } else {
		                    $allViewButton.hide();
		                    $viewTarget.removeClass('is-active');
		                }
		            }
		        }

		        allViewSetting();
		        window.addEventListener('resize', allViewSetting);

		        $allViewButton.on('click', function () {
		            if ($viewTarget.hasClass('is-active')) {
		                $viewTarget.removeClass('is-active');
		                $allViewButton.removeClass('is-active').find('.odc-all-view__text').text('전체보기');
		            } else {
		                $viewTarget.addClass('is-active');
		                $allViewButton.addClass('is-active').find('.odc-all-view__text').text('전체닫기');
		            }
		        })
		    },

		    /**
		     * data-ec-product 삽입 공통 처리
		     */
		    makeDataEcProduct: function(item) {
		        var self = this;
		        var param = {
		            "model_name":item.modelDisplayName.replace(/<[^>]*>?/g, ''),
		            "model_id":item.modelId,
		            "price":vcui.comma(item.price),
		            "ct_id":self.superCategoryId
		        }

		        return JSON.stringify(param);
		    },

		    /**
		     * 공통 : data-model-id 취합
		     */
		    getModelList: function (target, modelLength) {
		        var dataId = ''

		        target.find('[data-model-id]').each(function (idx) {
		            dataId += $(this).data('modelId') + (modelLength - 1 > idx ? ',' : '');
		        })

		        return dataId;
		    },

		    /**
		     * 공통 : 모델 목록의 display 값이 모두 disable인지 체크
		     */
		    getModelDisplayAllCheck: function (data) {
		        var count = 0;
		        var display = true;

		        for (var i = 0; i < data.length; i++) {
		            if (data[i]['display'] == 'disable') {
		                count++;
		            }
		        }

		        if (count == data.length) {
		            display = false
		        }

		        return display;
		    },

		    /**
		     * ODCC0001 : 상단 hero 배너
		     */
		    heroBannerSetting: function () {
		        var $hero = $('.odc-hero');
		        var $slide = $hero.find('.odc-hero__slide');
		        var $indicator = $hero.find('.odc-hero__indicator');
		        var $slideCurrent = $hero.find('.odc-hero__current');
		        var $totalCount = $hero.find('.odc-hero__total');
		        var currentIndex = $slide.siblings('.ui_carousel_current').index() || 1;

		        if ($slide.length > 1) {
		            $indicator.show();
		            $slideCurrent.text(currentIndex);
		            $totalCount.text($slide.length - 2);
		        }
		    },

		    heroBannerCarousel: function () {
		        var self = this;
		        var $hero = $('.odc-hero');
		        var track = document.querySelector('.odc-hero__track');

		        if ($hero.hasClass('is-random')) {
		            for (var i = track.children.length; i >= 0; i--) {
		                track.appendChild(track.children[Math.random() * i | 0]);
		            }

		            $hero.css('opacity', '1');
		        }

		        $hero.vcCarousel({
		            autoplay: true,
		            autoplaySpeed: 5000,
		            infinite: true,
		            pauseOnHover: false,
		            pauseOnFocus: false,
		            swipeToSlide: true,
		            slidesToShow: 1,
		            slidesToScroll: 1,
		            touchThreshold: 100,
		            cssEase: 'cubic-bezier(0.33, 1, 0.68, 1)',
		            speed: 150
		        }).on('carouselafterchange', function (e, slide) {
		            self.heroBannerSetting();
		        })

		        self.heroBannerSetting();
		    },

		    /**
		     * ODCC0002 2depth category requestData
		     */
		    twoDepthCategoryRequestData: function () {
		        var self = this;
		        var $twoDepthCategory = $('[data-module-name="twoDepthCategory"]');
		        var $target = $(".odc-sub-category__inner");
		        var template = '{{#each item in listData}}<a href="{{item.path}}" class="odc-sub-category__item">{{item.categoryName}}</a>{{/each}}';

		        if ($twoDepthCategory) {
		            lgkorUI.requestAjaxDataPost($twoDepthCategory.data('url'), {"superCategoryId": self.superCategoryId}, function (result) {
		                $target.html(vcui.template(template, {listData: result.data}));
		                self.allView($twoDepthCategory);
		            });
		        }
		    },

		    /**
		     * ODCC0003, ODCC0016 : show room type 1
		     */
		    showRoomType1Setting: function (idx) {
		        var prefixClass = '.odc-show-room-type1';
		        var $targetSlide = $(prefixClass).eq(idx);
		        var $slide = $targetSlide.find(prefixClass + '__slide');
		        var $indicator = $targetSlide.find(prefixClass + '__indicator');
		        var $dots = $targetSlide.find(prefixClass + '__dots');
		        var $slideCurrent = $targetSlide.find(prefixClass + '__current');
		        var $totalCount = $targetSlide.find(prefixClass + '__total');
		        var $currentSlide = $slide.siblings('.ui_carousel_current');
		        var currentIndex = $slide.siblings('.ui_carousel_current').index() || 1;
		        var clonePcProductDetail = $currentSlide.find(prefixClass + '__display.is-active').find(prefixClass + '__product').clone();

		        $targetSlide.find(prefixClass + '__detail').html(clonePcProductDetail);

		        if ($slide.length > 1) {
		            $indicator.show();
		            $dots.show();
		            $slideCurrent.text(currentIndex);
		            $totalCount.text($slide.length - 2);
		        } else {
		            // 기존 ui 공통 carousel.js와 보여주는 기능 중 겹치는 부분이 있는데 다르게 구현해야 하여 추가함
		            $indicator.hide();
		            $dots.hide();
		        }
		    },
		    showRoomType1Carousel: function () {
		        var self = this;
		        var $showRoomType1 = $('[data-module-name="showRoomType1"]');

		        if ($showRoomType1.length) {
		            self.showRoomType1RequestData();

		            $('.odc-show-room-type1__body').each(function (idx) {
		                $(this).vcCarousel({
		                    infinite: true,
		                    pauseOnHover: false,
		                    pauseOnFocus: false,
		                    swipeToSlide: true,
		                    slidesToShow: 1,
		                    slidesToScroll: 1,
		                    arrows: true,
		                    dotsSelector: '.ui_carousel_dots',
		                    touchThreshold: 100,
		                    cssEase: 'cubic-bezier(0.33, 1, 0.68, 1)',
		                    speed: 150,
		                    responsive: [
		                        {
		                            breakpoint: 768,
		                            settings: {
		                                arrows: false,
		                                slidesToShow: 1,
		                                slidesToScroll: 1,
		                            }
		                        },
		                        {
		                            breakpoint: 20000,
		                            settings: {
		                                arrows: true,
		                                slidesToShow: 1,
		                                slidesToScroll: 1,
		                            }
		                        }
		                    ]
		                }).on('carouselafterchange', function (e, slide) {
		                    self.showRoomType1Setting(idx);
		                })

		                self.showRoomType1Setting(idx);
		            });

		            self.showRoomType1Mark();

		            window.addEventListener('resize', self.showRoomType1Mark);
		        }
		    },
		    showRoomType1Mark: function () {
		        var prefixClass = '.odc-show-room-type1';

		        $(prefixClass).on('mouseenter', prefixClass + '__mark', function () {
		            var detailClone = $(this).closest(prefixClass + '__display').find(prefixClass + '__product').clone();

		            $(this).closest(prefixClass + '__item').find(prefixClass + '__display').removeClass('is-active');
		            $(this).closest(prefixClass + '__display').addClass('is-active');

		            $(this).closest(prefixClass).find(prefixClass + '__detail').html(detailClone);
		        })
		    },

		    showRoomType1RequestData: function () {
		        var self = this;
		        var prefixClass = '.odc-show-room-type1';
		        var $showRoomType1 = $('[data-module-name="showRoomType1"]');
		        var area = $showRoomType1.data('area');
		        var modelLength = $showRoomType1.find('[data-model-id]').length;
		        var dataId = self.getModelList($showRoomType1, modelLength);

		        $showRoomType1.each(function (idx) {
		            var $this = $showRoomType1.eq(idx);
		            lgkorUI.requestAjaxDataPost($(this).data('url'), {
		                "superCategoryId": self.superCategoryId,
		                "area": area,
		                "id": dataId
		            }, function (result) {
		                var modelData = result.data;

		                if (!self.getModelDisplayAllCheck(modelData)) {
		                    $this.hide();
		                    return;
		                }

		                modelData.forEach(function (data) {
		                    var $target = $this.find('[data-model-id="' + data.modelId + '"]');

		                    if (data.display == 'disable') {
		                        $target.remove();
		                        return;
		                    }

		                    $target.find(prefixClass + '__mark .blind').text(data.modelName);
		                    $target.find(prefixClass + '__product').attr('href', data.modelUrlPath);
		                    $target.find('.odc-product-name').html(data.modelDisplayName);
		                    $target.find('.odc-product-model').text(data.modelName);
		                    $target.find(prefixClass + '__product').attr('data-ec-product', self.makeDataEcProduct(data));

		                    if (data.price) {
		                        $target.find('.odc-product-price').text(vcui.comma(data.price) + '원');
		                    } else {
		                        $target.find('.odc-product-price').remove();
		                    }

		                    var $currentSlide = $this.find('.ui_carousel_current');
		                    var $cloneTarget = $currentSlide.find(prefixClass + '__display.is-active');

		                    if(!$cloneTarget.length) {
		                        $cloneTarget = $currentSlide.find(prefixClass + '__display').eq(0);
		                        $cloneTarget.addClass('is-active');
		                    }

		                    var clonePcProductDetail = $cloneTarget.find(prefixClass + '__product').clone();

		                    $this.find(prefixClass + '__detail').html(clonePcProductDetail);
		                });
		            });
		        })
		    },

		    /**
		     * ODCC0004, ODCC0014 : show room type 2
		     */
		    showRoomType2Carousel: function () {
		        var self = this;
		        var $showRoomType2 = $('[data-module-name="showRoomType2"]');
		        void 0;
				void 0;
		        if ($showRoomType2.length) {

		            self.showRoomType2RequestData();

		            setTimeout(function () {
		                var carouselEvent = function () {
		                    $('.odc-show-room-type2__body').each(function () {
		                        if ($(this).find('.odc-show-room-type2__item').length > 3) {
		                            $(this).find('.odc-show-room-type2__indicator').show();
		                            if ($(window).width() > 767) {
		                                $(this).vcCarousel({
		                                    infinite: true,
		                                    pauseOnHover: false,
		                                    pauseOnFocus: false,
		                                    swipeToSlide: true,
		                                    dots: false,
		                                    slidesToShow: 3,
		                                    slidesToScroll: 1,
		                                    arrows: true,
		                                    touchThreshold: 100,
		                                    cssEase: 'cubic-bezier(0.33, 1, 0.68, 1)',
		                                    speed: 150
		                                });
		                            } else {
		                                if ($(this).hasClass('ui_carousel_initialized')) {
		                                    $(this).vcCarousel('destroy');
		                                }
		                            }
		                        } else {
		                            $(this).find('.odc-show-room-type2__indicator').hide();
		                        }
		                    })

		                }
		                carouselEvent();

		                window.addEventListener('resize', carouselEvent)
		            }, 100);

		        }
		    },

		    showRoomType2RequestData: function () {
		        var self = this;
		        var $showRoomType2 = $('[data-module-name="showRoomType2"]');
		        var area = $showRoomType2.data('area');
		        var modelLength = $showRoomType2.find('[data-model-id]').length;
		        var dataId = self.getModelList($showRoomType2, modelLength);

		        $showRoomType2.each(function (idx) {
		            var $this = $showRoomType2.eq(idx);

		            lgkorUI.requestAjaxDataPost($(this).data('url'), {
		                "superCategoryId": self.superCategoryId,
		                "area": area,
		                "id": dataId
		            }, function (result) {
		                var modelData = result.data;

		                if (!self.getModelDisplayAllCheck(modelData)) {
		                    $this.hide();
		                    return;
		                }

		                modelData.forEach(function (data) {
		                    var $target = $this.find('[data-model-id="' + data.modelId + '"]');

		                    if (data.display == 'disable') {
		                        $target.remove();
		                        return;
		                    }

		                    $target.attr('href', data.modelUrlPath);
		                    $target.find('.odc-product-name').html(data.modelDisplayName);
		                    $target.find('.odc-product-model').text(data.modelName);
		                    $target.attr('data-ec-product', self.makeDataEcProduct(data));

		                    if (data.price) {
		                        $target.find('.odc-product-price').text(vcui.comma(data.price) + '원');
		                    } else {
		                        $target.find('.odc-product-price').remove();
		                    }
		                });
		            });
		        })
		    },

		    /**
		     * ODCC0005, ODCC0018 : 제품 추천 탭
		     */
		    recommendedTab: function () {
		        var self = this;
		        var $tabWrap = $('.odc-recommend-tab');
		        var $tab = $('.odc-recommend-tab__button');
		        var $tabPanel = $('.odc-recommend-tab__panel');

		        self.recommendedTabRequestData();

		        $tab.on('click', function () {
		            var $targetWrap = $(this).closest($tabWrap);
		            var targetId = $(this).data('panelTarget');

		            $targetWrap.find($tab).removeClass('is-active');
		            $(this).addClass('is-active');

		            $targetWrap.find($tabPanel).removeClass('is-active');
		            $targetWrap.find('#' + targetId).addClass('is-active');
		        });
		    },

		    recommendedTabRequestData: function () {
		        var self = this;
		        var $recommendTab = $('[data-module-name="recommendTab"]');
		        var area = $recommendTab.data('area');
		        var modelLength = $recommendTab.find('[data-model-id]').length;
		        var dataId = self.getModelList($recommendTab, modelLength);

		        if ($recommendTab) {
		            $recommendTab.each(function (idx) {
		                var $this = $recommendTab.eq(idx);

		                lgkorUI.requestAjaxDataPost($this.data('url'), {
		                    "superCategoryId": self.superCategoryId,
		                    "area": area,
		                    "id": dataId
		                }, function (result) {
		                    var modelData = result.data;

		                    if (!self.getModelDisplayAllCheck(modelData)) {
		                        $this.hide();
		                        return;
		                    }

		                    modelData.forEach(function (data) {
		                        var $target = $this.find('[data-model-id="' + data.modelId + '"]');

		                        if (data.display == 'disable') {
		                            $target.remove();
		                            return;
		                        }

		                        $target.find('.odc-product-image img').attr({
		                            'src': data.mediumImageUrl,
		                            'alt': data.modelDisplayName
		                        })
		                        $target.attr('href', data.modelUrlPath);
		                        $target.find('.odc-product-name').html(data.modelDisplayName);
		                        $target.find('.odc-product-model').text(data.modelName);
		                        $target.attr('data-ec-product', self.makeDataEcProduct(data));

		                        if (data.price) {
		                            var price = vcui.comma(data.price) + '원';
		                            var soldOutHtml = '<span class="odc-product-badge"><span class="odc-badge odc-badge--gray">일시품절</span></span>'

		                            if (data.display == "soldout") {
		                                $target.find('.odc-product-price').addClass('is-soldout');
		                                price = price + soldOutHtml;
		                            }

		                            $target.find('.odc-product-price').html(price);
		                        } else {
		                            $target.find('.odc-product-price').remove();
		                        }
		                    });
		                });
		            });

		            setTimeout(function(){
		                $recommendTab.find('.odc-recommend-tab__panel').each(function(idx){
		                    if($(this).find('.odc-recommend-tab__product .odc-recommend-tab__item').length == 0){
		                        var $removeTarget = $(this);
		                        $removeTarget.closest($recommendTab).find('[data-panel-target='+$removeTarget.attr('id')+']').remove();
		                        $removeTarget.remove();
		                    }
		                })

		                $recommendTab.each(function (idx) {
		                    if($(this).find('.odc-recommend-tab__button.is-active').length == 0){
		                        $(this).find('.odc-recommend-tab__button').eq(0).addClass('is-active');
		                        $(this).find('.odc-recommend-tab__button').eq(0).click();
		                    }
		                });
		            }, 100);
		        }
		    },

		    /**
		     * ODCC0006, ODCC0019 : 제품 추천 리스트
		     */
		    recommendedListRequestData: function () {
		        var self = this;
		        var $recommendList = $('[data-module-name="recommendList"]');
		        var area = $recommendList.data('area');
		        var modelLength = $recommendList.find('[data-model-id]').length;
		        var dataId = self.getModelList($recommendList, modelLength);

		        if ($recommendList) {
		            $recommendList.each(function (idx) {
		                var $this = $recommendList.eq(idx);

		                lgkorUI.requestAjaxDataPost($this.data('url'), {
		                    "superCategoryId": self.superCategoryId,
		                    "area": area,
		                    "id": dataId
		                }, function (result) {

		                    var modelData = result.data;

		                    if (!self.getModelDisplayAllCheck(modelData)) {
		                        $this.hide();
		                        return;
		                    }

		                    modelData.forEach(function (data) {
		                        var $target = $this.find('[data-model-id="' + data.modelId + '"]');

		                        if (data.display == 'disable') {
		                            $target.remove();
		                            return;
		                        }

		                        $target.find('.odc-product-image img').attr({
		                            'src': data.mediumImageUrl,
		                            'alt': data.modelDisplayName
		                        })
		                        $target.attr('href', data.modelUrlPath);
		                        $target.find('.odc-product-name').html(data.modelDisplayName);
		                        $target.find('.odc-product-model').text(data.modelName);
		                        $target.attr('data-ec-product', self.makeDataEcProduct(data));

		                        if (data.price) {
		                            var price = vcui.comma(data.price) + '원';
		                            var soldOutHtml = '<span class="odc-product-badge"><span class="odc-badge odc-badge--gray">일시품절</span></span>'

		                            if (data.display == "soldout") {
		                                $target.find('.odc-product-price').addClass('is-soldout');
		                                price = price + soldOutHtml;
		                            }

		                            $target.find('.odc-product-price').html(price);
		                        } else {
		                            $target.find('.odc-product-price').remove();
		                        }
		                    });

		                    self.allView($this.find('[data-all-view]'));
		                });
		            });
		        }
		    },

		    /**
		     * ODCC0007 : 인기제품 랭킹
		     */
		    popularProductRequestData: function () {
		        var self = this;
		        var $popularProduct = $('[data-module-name="popularProduct"]');
		        var area = $popularProduct.data('area');

		        if ($popularProduct) {
		            var $target = $(".odc-popular-product__list");
		            var template =
		                '{{#each item in listData}}' +
		                '<a href="{{item.link}}" class="odc-popular-product__item" data-model-id="65ART90EKPA" data-ec-product="{{item.ecProductData}}">' +
		                '<div class="odc-product-image">' +
		                '<span class="odc-popular-product__number">{{item.ranking}}</span>' +
		                '<img src="{{item.mediumImageUrl}}" alt="{{item.modelDisplayName}}">' +
		                '</div>' +
		                '<div class="odc-popular-product__info">' +
		                '<p class="odc-product-name">{{item.modelDisplayName}}</p>' +
		                '<p class="odc-product-model">{{item.modelName}}</p>' +
		                '</div>' +
		                '</a>' +
		                '{{/each}}';

		            lgkorUI.requestAjaxDataPost($popularProduct.data('url'), {
		                "superCategoryId": self.superCategoryId,
		                "area": area
		            }, function (result) {
		                result.data.forEach(function (data) {
		                    data.ecProductData = self.makeDataEcProduct(data);
		                });
		                $target.html(vcui.template(template, {listData: result.data}));
		            });
		        }
		    },

		    /**
		     * ODCC0009 : 베스트 리뷰
		     */
		    bestReviewRequestData: function () {
		        var self = this;
		        var $bestReview = $('[data-module-name="bestReview"]');
		        var area = $bestReview.data('area');

		        if ($bestReview) {
		            var $target = $(".odc-best-review__list");
		            var template =
		                '{{#each item in listData}}' +
		                '<a href="{{item.storyUrl}}" class="odc-best-review__item">' +
		                '<div class="odc-best-review__image" style="background-image: url({{item.image}})"></div>' +
		                '<div class="odc-best-review__info">' +
		                '<p class="odc-best-review__title">{{item.storyTitle}}</p>' +
		                '{{#if item.tags}}' +
		                '<div class="odc-best-review__row">' +
		                '{{#each tag in item.tags}}' +
		                '<span class="odc-best-review__tag">{{tag.tagName}}</span>' +
		                '{{/each}}' +
		                '</div>' +
		                '{{/if}}' +
		                '<p class="odc-best-review__date">{{item.lastUpdateDate}}</p>' +
		                '</div>' +
		                '</a>' +
		                '{{/each}}';

		            lgkorUI.requestAjaxDataPost($bestReview.data('url'), {
		                "superCategoryId": self.superCategoryId,
		                "storyType": "customer_review",
		                "area": area
		            }, function (result) {
		                if(result.data.length) {
		                    result.data.forEach(function (data) {
		                        data.image = "'" + data.storyListThumbnailPath + data.storyListThumbnailServerName + "'";
		                    });
		                    $target.html(vcui.template(template, {listData: result.data}));
		                } else {
		                    $bestReview.hide();
		                }
		            });
		        }
		    },

		    /**
		     * ODCC0010 : 기획전/프로모션
		     */
		    exhibitionPromotionRequestData: function () {
		        var self = this;
		        var $exhibitionPromotion = $('[data-module-name="exhibitionPromotion"]');
		        var area = $exhibitionPromotion.data('area');
		        var dataId = $exhibitionPromotion.data('id');

		        if ($exhibitionPromotion) {
		            var $target = $exhibitionPromotion.find(".odc-grid-list__inner");
		            var template =
		                '{{#each item in listData}}' +
		                '<a href="{{item.promoId}}" class="odc-grid-list__item">' +
		                '<div class="odc-grid-list__image" style="background-image: url({{item.promoCreative}})"></div>' +
		                '<div class="odc-grid-list__info">' +
		                '{{#if item.promoType}}' +
		                '<div class="odc-grid-list__row">' +
		                '<span class="odc-grid-list__flag">{{item.promoType}}</span>' +
		                '</div>' +
		                '{{/if}}' +
		                '<p class="odc-grid-list__title">{{item.promoName}}</p>' +
		                '<p class="odc-grid-list__date">{{item.promoDate}}</p>' +
		                '</div>' +
		                '</a>' +
		                '{{/each}}';

		            lgkorUI.requestAjaxDataPost($exhibitionPromotion.data('url'), {
		                "superCategoryId": self.superCategoryId,
		                "area": area,
		                "id": dataId
		            }, function (result) {
		                if(result.data.length) {
		                    result.data.forEach(function(data) {
		                        data.promoCreative = "'" + data.promoCreative + "'";
		                    });

		                    $target.html(vcui.template(template, {listData: result.data}));
		                } else {
		                    $exhibitionPromotion.hide();
		                }
		            });
		        }
		    },

		    /**
		     * ODCC0011 : 이벤트
		     */
		    eventRequestData: function () {
		        var self = this;
		        var $event = $('[data-module-name="event"]');
		        var area = $event.data('area');
		        var dataId = $event.data('id');

		        if ($event) {
		            var $target = $event.find(".odc-grid-list__inner");
		            var template =
		                '{{#each item in listData}}' +
		                '<a href="{{item.eventUrl}}" class="odc-grid-list__item">' +
		                '<div class="odc-grid-list__image" style="background-image: url({{item.eventListThumbnailPath}})"></div>' +
		                '<div class="odc-grid-list__info">' +
		                '<div class="odc-grid-list__row">' +
		                '{{#if item.eventGubun}}<span class="odc-grid-list__flag">{{item.eventGubun}}</span>{{/if}}' +
		                '{{#if item.eventType}}<span class="odc-grid-list__flag">{{item.eventType}}</span>{{/if}}' +
		                '</div>' +
		                '<p class="odc-grid-list__title">{{item.eventTitle}}</p>' +
		                '<p class="odc-grid-list__date">{{item.eventFromDate}} ~ {{item.eventToDate}}</p>' +
		                '</div>' +
		                '</a>' +
		                '{{/each}}';

		            lgkorUI.requestAjaxDataPost($event.data('url'), {
		                "superCategoryId": self.superCategoryId,
		                "area": area,
		                "id": dataId,
		                "eventStatus": "progress",
		                "eventGubun": ""
		            }, function (result) {
		                var resultData = result.data;

		                if(resultData.length) {
		                    resultData.forEach(function (data) {
		                        if (!data.eventType) {
		                            data.eventType = "";
		                        }

		                        data.eventListThumbnailPath = "'" + data.eventListThumbnailPath + "'";
		                    });

		                    $target.html(vcui.template(template, {listData: resultData}));
		                } else {
		                    $event.hide();
		                }
		            });
		        }
		    },

		    /**
		     * ODCC0015 : 보유제품 연계배너
		     */
		    myProductRequestData: function () {
		        var $myProduct = $('[data-module-name="myProduct"]');
		        var myPossibleTemplate = '{{item.userNm}}님, 등록 가능한 제품이 <span class="odc-my-product__count">{{item.productNm}}</span>개 있습니다.'
		        var myProductTemplate = '{{item.userNm}}님, 현재 보유제품이 <span class="odc-my-product__count">{{item.productNm}}</span>개 있습니다.'

		        if ($myProduct) {
		            lgkorUI.requestAjaxDataPost($myProduct.data('url'), {}, function (result) {
		                var resultData = {};

		                if (result.data.isLogin) {
		                    if (result.data.myProductCount == 0) {
		                        if(result.data.myPossibleProductCount == 0) {
		                            $myProduct.find('.is-login').addClass('is-active');
		                        } else {
		                            resultData = { userNm: result.data.userNm, productNm: result.data.myPossibleProductCount }
		                            $myProduct.find('.is-my-product .odc-my-product__title').html(vcui.template(myPossibleTemplate, {item: resultData}));
		                            $myProduct.find('.is-my-product').addClass('is-active');
		                        }
		                    } else {
		                        if(result.data.myPossibleProductCount == 0) {
		                            resultData = { userNm: result.data.userNm, productNm: result.data.myProductCount }
		                            $myProduct.find('.is-my-product .odc-my-product__title').html(vcui.template(myProductTemplate, {item: resultData}));
		                        } else {
		                            resultData = { userNm: result.data.userNm, productNm: result.data.myPossibleProductCount }
		                            $myProduct.find('.is-my-product .odc-my-product__title').html(vcui.template(myPossibleTemplate, {item: resultData}));
		                        }
		                        $myProduct.find('.is-my-product').addClass('is-active');
		                    }

		                } else {
		                    $myProduct.find('.is-logoff').addClass('is-active');
		                }
		            });
		        }
		    }
		};

		$(function(){
		    odc.init();
		});